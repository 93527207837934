<div class="grid grid-cols-6 gap-x-2 gap-y-4">
  <div class="col-start-2 col-span-4">
   <form [formGroup]="addForm">
      <div><textarea class="rounded h-24 w-full" id="new-note-body" placeholder="New note..." formControlName="body"></textarea></div>
      <div class=" grid grid-cols-1 justify-items-end">
        <button class="rounded bg-warmgray-700 text-white text-sm p-2 font-bold border-2" (click)="onSave()">Save</button>
      </div>
    </form>
  </div>
</div>


<div class="hidden" *ngIf="notes.length === 0; then emptyBlock else displayBlock"></div>

<ng-template #emptyBlock>
  <div class="grid grid-cols-6 gap-x-2 gap-y-4">
    <div class="col-start-2 col-span-4 pt-4"><hr /></div><div class="col-span-1"></div>
    <div class="col-span-1">
      <div class="grid grid-cols-1 justify-items-end">
        <div class="rounded-full bg-gray-700  text-gray-100 p-1">:-(</div>
      </div>
    </div>
    <div class="col-span-4">
      <div class="w-full h-24 border-dashed border-gray-700 border-2 text-center text-3xl text-gray-400 bg-gray-50 rounded">No notes</div>
    </div>
  </div>
</ng-template>

<ng-template #displayBlock>
  <div *ngFor="let note of notes; let i = index">
    <div class="grid grid-cols-6 gap-x-2 gap-y-4">
      <div class="col-start-2 col-span-4 pt-4"><hr /></div><div class="col-span-1"></div>
      <div class="col-span-1">
        <div class="grid grid-cols-1 justify-items-end">
          <div class="rounded-full bg-gray-700  text-gray-100 p-1">{{ note.createdBy | uppercase | slice:0:2 }}</div>
        </div>
      </div>
      <div *ngIf="note.id !== selectedNoteId" class="col-span-4" (click)="onSelect(note.id)">
        <div class="w-full h-24 p-4 border-gray-700 border-2 text-gray-700 bg-gray-50 rounded">{{ note.body }}</div>
      </div>
      <div *ngIf="note.id === selectedNoteId" class="col-span-4">
        <form [formGroup]="updateForm">
          <div><textarea class="rounded h-24 w-full p-4" id="update-note-{{ note.id }}" formControlName="body">{{ note.body }}</textarea></div>
          <div class="grid grid-cols-1">
            <div class="flex justify-end">
              <button class="text-red-600 text-sm p-4 underline" (click)="onDelete(note.id)">Delete</button>
              <button class="text-gray-700 text-sm p-4 underline" (click)="onCancel()">Cancel</button>
              <button class="rounded bg-warmgray-700 text-white text-sm p-2 font-bold border-2" (click)="onUpdate(note.id)">Update</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
