<!-- main sidebar -->
<div class="fixed w-12 py-2 px-4 h-screen bg-warmgray-600">
  <div class="grid grid-cols-1 gap-y-4 text-white ">
    <button class="" [routerLink]="['jobs']"><i class="pi pi-home"></i></button>
    <button class="" [routerLink]="['profile']"><i class="pi pi-user"></i></button>
  </div>
</div>

<!-- main dashboard container -->
<div class="flex">
  <div class="flex-none w-12"></div>
  <div class="flex-auto justify-center pt-8 px-64">
    <div class="bg-white rounded-xl py-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
