import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JobTrackerContainerComponent } from './feats/customer-portal/components/job-tracker-container/job-tracker-container.component';
import { CustomerPortalContainerComponent } from './feats/customer-portal/components/customer-portal-container/customer-portal-container.component';
import { RequestFormContainerComponent } from './feats/customer-portal/components/request-form-container/request-form-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RequestFormReceiptComponent } from './feats/customer-portal/components/request-form-receipt/request-form-receipt.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AdminPortalContainerComponent } from './feats/admin-portal/components/admin-portal-container/admin-portal-container.component';
import {ButtonModule} from "primeng/button";
import { AdminDashboardContainerComponent } from './feats/admin-portal/components/admin-dashboard-container/admin-dashboard-container.component';
import { DashboardContainerDirective } from './feats/admin-portal/directives/dashboard-container.directive';
import { AdminHomeDashboardComponent } from './feats/admin-portal/components/admin-home-dashboard/admin-home-dashboard.component';
import { AdminArchiveDashboardComponent } from './feats/admin-portal/components/admin-archive-dashboard/admin-archive-dashboard.component';
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {CardModule} from "primeng/card";
import {InputTextModule} from "primeng/inputtext";
import { RepairJobDetailsComponent } from './feats/admin-portal/components/repair-job-details/repair-job-details.component';
import {TabViewModule} from "primeng/tabview";
import { RepairJobContainerComponent } from './feats/admin-portal/components/repair-job-container/repair-job-container.component';
import { RepairJobNotesComponent } from './feats/admin-portal/components/repair-job-notes/repair-job-notes.component';
import { RepairJobImagesComponent } from './feats/admin-portal/components/repair-job-images/repair-job-images.component';
import {PanelModule} from "primeng/panel";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToolbarModule} from "primeng/toolbar";
import { RepairJobDetailsFormComponent } from './feats/admin-portal/components/repair-job-details-form/repair-job-details-form.component';
import {SkeletonModule} from "primeng/skeleton";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DividerModule} from "primeng/divider";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import { AdminProfileComponent } from './feats/admin-portal/components/admin-profile/admin-profile.component';
import { AuthCallbackComponent } from './feats/authentication/components/auth-callback/auth-callback.component';
import {FirebaseAppModule, initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {environment} from "../environments/environment";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {NgOptimizedImage} from "@angular/common";
import {FileUploadModule} from "primeng/fileupload";
import { NewRepairJobContainerComponent } from './feats/admin-portal/components/new-repair-job-container/new-repair-job-container.component';
import { NewRepairJobFormComponent } from './feats/admin-portal/components/new-repair-job-form/new-repair-job-form.component';
import { NewRepairJobReceiptComponent } from './feats/admin-portal/components/new-repair-job-receipt/new-repair-job-receipt.component';
import { NewRepairJobSubmitComponent } from './feats/admin-portal/components/new-repair-job-submit/new-repair-job-submit.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {ImageModule} from "primeng/image";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService} from "primeng/api";
import {resourceServer} from "./config/resource-server.config";
import {AngularFireModule} from "@angular/fire/compat";
import {getAuth, provideAuth} from "@angular/fire/auth";

@NgModule({
  declarations: [
    AppComponent,
    JobTrackerContainerComponent,
    CustomerPortalContainerComponent,
    RequestFormContainerComponent,
    RequestFormReceiptComponent,
    AdminPortalContainerComponent,
    AdminDashboardContainerComponent,
    DashboardContainerDirective,
    AdminHomeDashboardComponent,
    AdminArchiveDashboardComponent,
    RepairJobDetailsComponent,
    RepairJobContainerComponent,
    RepairJobNotesComponent,
    RepairJobImagesComponent,
    RepairJobDetailsFormComponent,
    AdminProfileComponent,
    AuthCallbackComponent,
    NewRepairJobContainerComponent,
    NewRepairJobFormComponent,
    NewRepairJobReceiptComponent,
    NewRepairJobSubmitComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ButtonModule,
    TableModule,
    TagModule,
    CardModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    ToolbarModule,
    SkeletonModule,
    ProgressSpinnerModule,
    DividerModule,
    ScrollPanelModule,
    AuthModule.forRoot({
      domain: 'watch-and-clock-repair.au.auth0.com',
      clientId: 'sCUjyfus2tkjUgNejImuqLMQqM6FbHKK',
      authorizationParams: {
        redirect_uri: `${window.location.origin}/admin`
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${resourceServer}/secure/api/*`,
            tokenOptions: {
              authorizationParams: {
                audience: resourceServer
              }
            }
          }

        ]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    NgOptimizedImage,
    FileUploadModule,
    InputSwitchModule,
    ImageModule,
    ConfirmDialogModule
  ],
  providers: [
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
