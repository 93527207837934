import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {
  FormArray,
  FormBuilder, FormControl,
  FormGroup,
} from "@angular/forms";
import {RepairJob} from "../../../../domain/repair-job.dto";
import {formatDateForHtmlInput, formatHtmlDateInputToDateObj} from "../../../../utils/date.util";
import * as jsonPatch from 'fast-json-patch';
import {SecureResourceService} from "../../../../services/secure-resource.service";

const STATE_NEW: String = 'new';
const STATE_LOADING: String = "loading";
const STATE_READY: String = "ready";
const STATE_SUBMITTED: string = "submitted";
const STATE_ERROR: string = "error";

@Component({
  selector: 'app-repair-job-details-form',
  templateUrl: './repair-job-details-form.component.html',
  styleUrls: ['./repair-job-details-form.component.scss']
})
export class RepairJobDetailsFormComponent implements OnChanges {
  @Input() formData: RepairJob | null = null;
  @Input() isNew: boolean = false;
  @Input() toSubmit: boolean = false;
  @Output() formEvent = new EventEmitter<string>();

  state: String = this.isNew ? STATE_NEW : STATE_LOADING;
  form = this.formBuilder.group({
    jobNumber: [''],
    status: [''],
    postedDate: [''],
    arrivalDate: [''],
    expectedCompletionDate: [''],
    estimatedCost: [0],
    finalCost: [0],
    postageTrackingNumber: [''],
    createdDate: [new Date()],
    customer: this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      postalAddress: [''],
      phoneNumber: ['']
    }),
    timepiece: this.formBuilder.group({
      brand: [''],
      caseMaterial: [''],
      caseCondition: [''],
      bandMaterial: [''],
      bandCondition: [''],
      faceColour: [''],
      faceCondition: [''],
      serialNumbers: this.formBuilder.array( [])
    }),
    problemDetail: this.formBuilder.group({
      movement: [''],
      description: [''],
      todo: [''],
      requiredParts: ['']
    })
  });

  get timepiece() {
    return this.form.get('timepiece') as FormGroup;
  }

  get serialNumbers() {
    return this.timepiece.get('serialNumbers') as FormArray;
  }
  constructor(
    private formBuilder: FormBuilder,
    private resource: SecureResourceService
  ) {}

  ngOnChanges() {
    if (this.isNew) {
      return;
    }

    if (this.toSubmit) {
      this.onSubmit();
      return;
    }

    if (this.formData === null || this.formData === undefined) {
      this.state = STATE_LOADING;
      return;
    }

    this.state = STATE_READY;

    const {customerRequest, ...editableFormData} = this.formData;

    const serialNumbers = Object.values(editableFormData.timepiece.serialNumbers);
    const postedDate = formatDateForHtmlInput(editableFormData.postedDate);
    const arrivalDate = formatDateForHtmlInput(editableFormData.arrivalDate);
    const expectedCompletionDate = formatDateForHtmlInput(editableFormData.expectedCompletionDate);

    serialNumbers.forEach(() => this.serialNumbers.push(new FormControl('')));

    this.form.patchValue({
      ...editableFormData,
      postedDate,
      arrivalDate,
      expectedCompletionDate,
      timepiece: {
        ...editableFormData?.timepiece,
        serialNumbers
      },
    });

  }


  onAddSerial() {
    this.serialNumbers.push(this.formBuilder.control(''));
  }

  onRemoveSerial(position: number) {
    this.serialNumbers.removeAt(position);
  }

  onSubmit() {
    if (!this.formData) {
      console.error('There is no original form data');
      return
    }

    this.state = 'submitting';

    const submittedData = this.form.getRawValue();
    const postedDate = formatHtmlDateInputToDateObj(submittedData.postedDate);
    const arrivalDate = formatHtmlDateInputToDateObj(submittedData.arrivalDate);
    const expectedCompletionDate = formatHtmlDateInputToDateObj(submittedData.expectedCompletionDate);
    const notes = this.formData.notes;
    const customerRequest = this.formData.customerRequest;
    const images = this.formData.images;

    const patch = jsonPatch.compare(
      this.formData,
      {
        ...submittedData,
        notes,
        customerRequest,
        images,
        postedDate,
        arrivalDate,
        expectedCompletionDate
      }
    );

    const response = this.resource.updateRepairJob(this.formData.jobNumber, patch);

    response.subscribe(
      () => {
        this.state = STATE_SUBMITTED;
      },
      error => {
        console.error(error);
        this.state = STATE_ERROR;
      }, () => {
        this.formEvent.emit(STATE_SUBMITTED);
      });
  }
}
