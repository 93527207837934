<div class="absolute inset-0 shadow-lg bg-warmgray-600 h-64"></div>
<div class="relative p-4">
    <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
      <button class="text-white underline p-4 rounded-md hover:bg-gray-800 hover:no-underline float-right" (click)="auth.logout()">Logout</button>
    </ng-container>
    <ng-template #loggedOut>
      <button class="text-white underline p-4 rounded-md hover:bg-gray-800 hover:no-underline float-right" (click)="wacrAuth.login()">Login</button>
    </ng-template>
</div>
<div class="relative max-w-3xl mx-auto">
    <div class="relative text-white text-5xl text-center py-8">
        Watch and Clock Repair Australia
    </div>
    <div class="relative bg-white shadow-lg rounded-lg p-20">
        <div class="mx-w-md mx-auto">
            <div class="divide-y divide-gray">
                <div class="p-8">
                    <h1 class="text-4xl py-2">Check your repair status</h1>
                    <p class="text-base py-6">Have you booked a repair with us and want to know its progress? Click below to find out where it's at!</p>
                    <button class="text-2xl p-2 font-bold" routerLink="/track">Check your repair now</button>
                </div>
<!--                <div class="p-8">-->
<!--                    <h1 class="text-4xl py-2">Book a repair</h1>-->
<!--                    <p class="text-base py-6">Do you have a watch or clock that needs some attention? We are here to help. Click below to get started.</p>-->
<!--                    <button class="text-2xl p-2 font-bold" routerLink="/request">Book a repair now</button>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</div>
