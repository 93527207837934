import { Component } from '@angular/core';

@Component({
  selector: 'app-new-repair-job-submit',
  templateUrl: './new-repair-job-submit.component.html',
  styleUrls: ['./new-repair-job-submit.component.scss']
})
export class NewRepairJobSubmitComponent {

}
