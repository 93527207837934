import { Component } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {Auth, signInWithCustomToken} from "@angular/fire/auth";
import {SecureResourceService} from "../../../../services/secure-resource.service";

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent {

  constructor(
    public auth: AuthService,
    private firebaseAuth: Auth,
    private resource: SecureResourceService
  ) {
  }

  refresh() {
    this.auth.user$.subscribe((user) => {
      const uid = user?.email ? user?.email : 'no-uid';
      this.resource.getFirebaseCustomToken(uid).subscribe((data) => {
        const token = data.token;
        signInWithCustomToken(this.firebaseAuth, token).then((userCredential) => {});
      });
    });
  }
}
