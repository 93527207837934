import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Note} from "../../../../domain/note.dto";
import {throwError} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {SecureResourceService} from "../../../../services/secure-resource.service";
import {AuthService} from "@auth0/auth0-angular";
import {switchMap} from "rxjs/operators";
import * as jsonPatch from 'fast-json-patch';

@Component({
  selector: 'app-repair-job-notes',
  templateUrl: './repair-job-notes.component.html',
  styleUrls: ['./repair-job-notes.component.scss']
})
export class RepairJobNotesComponent implements OnChanges {
  @Input() jobNumber: string | undefined = undefined;
  addForm = this.formBuilder.group({body: ['']});
  updateForm = this.formBuilder.group({body: ['']});
  notes: Note[] = []
  selectedNoteId: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private resource: SecureResourceService,
    public auth: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchNotes();
  }

  onSave() {
    this.auth.user$.pipe(
      switchMap((user) => {
        if (!this.jobNumber) {
          return throwError("A [jobNumber] must be provided to create a new note.");
        }

        if (!user) {
          return throwError("Requires logged in user");
        }

        if (!user.email) {
          return throwError("User requires email");
        }

        const formData = this.addForm.getRawValue();
        const body = formData?.body ? formData.body : '';
        const note: Note = {
          createdBy: user.email,
          body
        }

        return this.resource.addNote(this.jobNumber, note);
      })
    ).subscribe(() => {
      this.fetchNotes();
    }, (error) => {
      console.error(error);
    });
  }

  onSelect(noteId: number | undefined) {
    if (!noteId) {
      return;
    }

    this.selectedNoteId = noteId;

    const originalNote = this.getNote(noteId);

    this.updateForm.patchValue({body: originalNote?.body})
  }

  onCancel() {
    this.resetUpdate();
  }

  onDelete(noteId: number | null | undefined) {
    if (!noteId) {
      console.error("no notedId");
      return;
    }
    this.resource.removeNote(noteId).subscribe(() => {
      this.fetchNotes();
    }, (error) => {
      console.error(error);
    });
  }

  onUpdate(noteId: number) {

    this.auth.user$.pipe(
      switchMap((user) => {
        if (!noteId) {
          return throwError("no notedId");
        }

        if (!user) {
          return throwError("Requires logged in user");
        }

        if (!user.email) {
          return throwError("User requires email");
        }

        const originalNote = this.notes.find(note => note.id === noteId);

        if (!originalNote) {
          return throwError(`Could not find original note in list with [id]=${noteId}`)
        }

        const formData = this.updateForm.getRawValue();
        const body = formData?.body ? formData.body : '';
        const updatedNote: Note = {
          ...originalNote,
          modifiedBy: user.email,
          body
        }

        const patch = jsonPatch.compare(originalNote, updatedNote);

        return this.resource.updateNote(noteId, patch);
      })
    ).subscribe(() => {
      this.resetUpdate();
      this.fetchNotes();
    }, (error) => {
      console.error(error);
      this.resetUpdate();
    });
  }

  private fetchNotes() {
    if (!this.jobNumber) {
      this.notes = [];
      return;
    }

    this.resource.getNotes(this.jobNumber).subscribe(
      data => {
        this.notes = data.sort((a, b) => {
          const aCreatedDate = a.createdDate  ? new Date(a.createdDate).getTime() : 0;
          const bCreatedDate = b.createdDate ? new Date(b.createdDate).getTime() : 0;
          return bCreatedDate - aCreatedDate;
        });
      },
      error => {
        this.notes = [];
        console.error(error);
      }
    );
  }

  private getNote(noteId: number): Note | undefined {
    return this.notes?.find(note => note.id === noteId);
  }

  private resetUpdate() {
    this.updateForm.reset();
    this.selectedNoteId = null;
  }
}
