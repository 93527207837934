<div class=" flex justify-end rounded-lg mb-4 bg-gray-100 p-2">
  <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" *ngIf="mode === STATE_VIEW" (click)="onAdd()">Add</button>
  <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" *ngIf="mode === STATE_UPLOAD" (click)="onCancel()">Cancel</button>
  <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" *ngIf="selectedFiles" (click)="onUpload()">Upload</button>
</div>

<p-confirmDialog></p-confirmDialog>
<div *ngIf="mode === STATE_VIEW" class="grid grid-cols-4 gap-3 rounded-lg p-10 border-gray-400 bg-gray-100 shadow">
  <div  *ngFor="let imageMeta of imageMetaList">
    <div class="relative">
      <p-image [id]="'img-' + imageMeta.reference" class="rounded-lg" src="{{ imageMeta.url }}" [preview]="true" alt="Image" width="450" height="450" (onShow)="onShow(imageMeta)" #image>
        <ng-template pTemplate="indicator">
          <button class="text-white mx-3 hover:text-green-400"><i class="pi pi-eye"></i></button>
          <button class="text-white mx-3 hover:text-red-400" (click)="confirmDelete(imageMeta)"><i class="pi pi-trash"></i></button>
        </ng-template>
      </p-image>
    </div>
  </div>
</div>

<div *ngIf="mode === STATE_UPLOAD" class="flex justify-center rounded-lg w-full h-48 border-gray-400 shadow">
  <input type="file" (change)="onChooseFile($event)">
</div>
