import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {RepairJob} from "../domain/repair-job.dto";
import {resourceServer} from "../config/resource-server.config";
import {HttpClient} from "@angular/common/http";
import {snakeToCamel, camelToSnake, camelToSnakeStr} from "../utils/json.util";
import {map} from "rxjs/operators";
import {JsonPatch} from "../domain/json-patch.dto";
import {Note} from "../domain/note.dto";

const resourcePath = `${resourceServer}/secure/api`;

@Injectable({
  providedIn: 'root'
})
export class SecureResourceService {

  constructor(
    private http: HttpClient
  ) { }

  getRepairJobs(): Observable<RepairJob[]> {
    const response = this.http.get<Object[]>(`${resourcePath}/repair_job`);
    return response.pipe(map(res => res.map( obj => snakeToCamel(obj) as RepairJob)));
  }

  getRepairJob(jobNumber: String): Observable<RepairJob> {
    const response = this.http.get<Object[]>(`${resourcePath}/repair_job/${jobNumber}`);
    return response.pipe(map(res => snakeToCamel(res) as RepairJob));
  }

  updateRepairJob(jobNumber: String, patch: JsonPatch[]): Observable<RepairJob> {
    // const snakePatch = patch.map(op => { return {...op, path: camelToSnakeStr(op.path)} });
    const response = this.http.patch(`${resourcePath}/repair_job/${jobNumber}`, patch);
    return response.pipe(map(res => snakeToCamel(res) as RepairJob));
  }

  addNote(jobNumber: String, note: Note): Observable<any> {
    return this.http.post(`${resourcePath}/note/${jobNumber}`, camelToSnake(note));
  }

  addRepairJob(repairJob: any): Observable<any> {
    return this.http.post(`${resourcePath}/repair_job`, camelToSnake(repairJob));
  }

  getNotes(jobNumber: String): Observable<Note[]> {
    const response = this.http.get<Object[]>(`${resourcePath}/note/${jobNumber}`);
    return response.pipe(map(res => res.map( obj => snakeToCamel(obj) as Note)));
  }

  updateNote(noteId: number, patch: JsonPatch[]): Observable<Note> {
    const snakePatch = patch.map(op => { return {...op, path: camelToSnakeStr(op.path)} });
    const response = this.http.patch<Object[]>(`${resourcePath}/note/${noteId}`, snakePatch);
    return response.pipe(map(res => snakeToCamel(res) as Note));
  }

  removeNote(noteId: number): Observable<any> {
    return this.http.delete(`${resourcePath}/note/${noteId}`);
  }

  getFirebaseCustomToken(uid: string): Observable<{token: string}> {
    return this.http.get<{token: string}>(`${resourcePath}/token/${uid}`);
  }
}
