import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-dashboard-container',
  templateUrl: './admin-dashboard-container.component.html',
  styleUrls: ['./admin-dashboard-container.component.scss']
})
export class AdminDashboardContainerComponent {

}
