<div class="absolute inset-0 shadow-lg bg-warmgray-600 h-64"></div>
<div class="relative p-4">
    <button class="text-white underline p-4 rounded-md hover:bg-gray-800 hover:no-underline float-right">Login</button>
</div>
<div class="relative max-w-3xl mx-auto">
    <div class="relative py-8">
        <button class="text-3xl text-white" routerLink="/">&lt; Back</button>
    </div>
    <div class="relative bg-white shadow-lg rounded-lg p-20 mb-20">
        <div class="mx-w-md mx-auto">
            <div *ngIf="!formSubmitted">
                <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
                    <div class="grid grid-cols-2 gap-4 py-4">
                        <div>
                            <div class="pb-1">
                                <label for="firstName" class="">First name</label>
                            </div>
                            <div>
                                <input class="w-full" id="firstName" type="text" formControlName="first_name" placeholder="First name">
                            </div>
                        </div>
                        <div>
                            <div class="pb-1">
                                <label for="lastName">Last name</label>
                            </div>
                            <div>
                                <input class="w-full" id="lastName" type="text" formControlName="last_name" placeholder="Last name">
                            </div>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="pb-1">
                            <label for="postalAddress">Postal Address</label>
                        </div>
                        <div>
                            <input class="w-full" id="postalAddress" type="text" formControlName="postal_address" placeholder="Postal Address">
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div class="py-4">
                            <div class="pb-1">
                                <label for="email">Email</label>
                            </div>
                            <div>
                                <input class="w-full" id="email" type="text" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                        <div class="py-4">
                            <div class="pb-1">
                                <label for="phoneNumber">Phone Number</label>
                            </div>
                            <div>
                                <input class="w-full" id="phoneNumber" type="text" formControlName="phone_number" placeholder="Phone Number">
                            </div>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="pb-1">
                            <label for="problemDescription">Problem Description</label>
                        </div>
                        <div>
                            <textarea class="w-full" id="problemDescription" type="text" formControlName="problem_description" placeholder="Tell us about the problem"></textarea>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="pb-1">
                            <label for="repairRequests">Requests</label>
                        </div>
                        <div>
                            <textarea class="w-full" id="repairRequests" type="text" formControlName="repair_requests" placeholder="Requests"></textarea>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="pb-1">
                            <label for="spendingLimit">Spending Limit</label>
                        </div>
                        <div>
                            <!-- <div class="relative inline-block p-3 h-auto bg-gray-400">$</div> -->
                            <input class="w-1/2" id="spendingLimit" type="text" formControlName="spending_limit" placeholder="0.00">
                        </div>
                    </div>
                    <div class="py-4">
                        <button type="submit" class="p-4 bg-blue-400 text-white">Submit</button>
                    </div>
                </form>
            </div>
            <div *ngIf="formSubmitted">
                <div class="text-lg">
                    Thank you for subitting a request. Your job number is
                </div>
                <div class="text-4xl">
                    {{ jobNumber }}
                </div>
            </div>
        </div>
    </div>
</div>