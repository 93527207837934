import { Component, OnInit } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {WacrAuthService} from "../../../../services/wacr-auth.service";

@Component({
  selector: 'app-customer-portal-container',
  templateUrl: './customer-portal-container.component.html',
  styleUrls: ['./customer-portal-container.component.scss']
})
export class CustomerPortalContainerComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public wacrAuth: WacrAuthService
  ) { }

  ngOnInit(): void {
  }

}
