<div class="p-5">
  <div *ngIf="state === NewRepairJobState.STATE_NEW">
    <app-new-repair-job-form (state)="onStateChange($event)" (data)="onSubmit($event)"></app-new-repair-job-form>
  </div>
  <div *ngIf="state === NewRepairJobState.STATE_SUBMITTING">
    <app-new-repair-job-submit></app-new-repair-job-submit>
  </div>
  <div *ngIf="state === NewRepairJobState.STATE_COMPLETE">
    <app-new-repair-job-receipt [state]="responseState" [jobNumber]="jobNumber"></app-new-repair-job-receipt>
  </div>
</div>
