import {Component} from '@angular/core';
import {NewRepairJobState, NewRepairJobState as State} from "../../states/new-repair-job-state";
import {SecureResourceService} from "../../../../services/secure-resource.service";

@Component({
  selector: 'app-new-repair-job-container',
  templateUrl: './new-repair-job-container.component.html',
  styleUrls: ['./new-repair-job-container.component.scss']
})
export class NewRepairJobContainerComponent {
  state: string = State.STATE_NEW;
  responseState: string | null = null;
  jobNumber: string | null = null;

  constructor(
    private resource: SecureResourceService
  ) {}

  onSubmit($event: any) {
    this.resource.addRepairJob($event).subscribe({
        next: (data) => {
          this.jobNumber = data.jobNumber;
          this.onStateChange(State.STATE_SUCCESS);
        },
        error: () => { this.onStateChange(State.STATE_FAIL); }
      }
    );
  }

  onStateChange($event: string) {
    if ([State.STATE_SUCCESS, State.STATE_FAIL].includes($event)) {
      this.responseState = $event;
      this.state = State.STATE_COMPLETE;
    } else {
      this.state = $event;
    }
  }

  protected readonly NewRepairJobState = NewRepairJobState;
}
