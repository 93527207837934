import {Component, Input} from '@angular/core';
import {RepairJob} from "../../../../domain/repair-job.dto";

@Component({
  selector: 'app-repair-job-details',
  templateUrl: './repair-job-details.component.html',
  styleUrls: ['./repair-job-details.component.scss']
})
export class RepairJobDetailsComponent {
  @Input() job: RepairJob | null = null;

  get serialNumbers(): string[] {
    const serialNumbers = this.job?.timepiece?.serialNumbers;

    if (!serialNumbers) return [];

    if (Array.isArray(serialNumbers)) return serialNumbers;

    return Object.values(serialNumbers);
  }

  constructor() {
  }
}
