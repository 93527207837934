import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OpenResourceService } from 'src/app/services/open-resource.service';

@Component({
  selector: 'app-request-form-container',
  templateUrl: './request-form-container.component.html',
  styleUrls: ['./request-form-container.component.scss']
})
export class RequestFormContainerComponent implements OnInit {

  requestForm = new UntypedFormGroup({
    first_name: new UntypedFormControl(''),
    last_name: new UntypedFormControl(''),
    postal_address: new UntypedFormControl(''),
    phone_number: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    problem_description: new UntypedFormControl(''),
    repair_requests: new UntypedFormControl(''),
    spending_limit: new UntypedFormControl('')
  });

  formSubmitted = false;
  jobNumber = "Oops something went wrong.";

  constructor(
    private openRes: OpenResourceService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.openRes.addRepairRequest(this.requestForm.value).subscribe( data => {
      this.jobNumber = data.job_number;
      this.formSubmitted = true;
    });
  }

}
