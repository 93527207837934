<p-table
  #repairJobTable
  [value]="repairJobs"
  dataKey="jobNumber"
  [globalFilterFields]="['jobNumber', 'firstName', 'lastName', 'email']"
  [loading]="repairJobs.length === 0"
>
  <ng-template pTemplate="caption">
    <div class="flex">
            <span class="mr-auto">
              <button class="p-button" [routerLink]="['/admin', 'new-job']">New</button>
            </span>
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="repairJobTable.filterGlobal($any($event.target).value, 'contains')"
                  placeholder="Search"
                />
            </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" class="bg-white">
    <tr>
      <th>Job Number</th>
      <th>Customer Name</th>
      <th>Email</th>
      <th>Status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-repairJob>
    <tr>
      <td><a [routerLink]="repairJob.jobNumber">{{ repairJob.jobNumber }}</a></td>
      <td>{{ repairJob.firstName + " " + repairJob.lastName }}</td>
      <td>{{ repairJob.email }}</td>
      <td><p-tag severity="primary" [value]="repairJob.status"></p-tag></td>
    </tr>
  </ng-template>
</p-table>
