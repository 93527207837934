import { NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { CustomerPortalContainerComponent } from './feats/customer-portal/components/customer-portal-container/customer-portal-container.component';
import { JobTrackerContainerComponent } from './feats/customer-portal/components/job-tracker-container/job-tracker-container.component';
import { RequestFormContainerComponent } from './feats/customer-portal/components/request-form-container/request-form-container.component';
import {
  AdminPortalContainerComponent
} from "./feats/admin-portal/components/admin-portal-container/admin-portal-container.component";
import {
  AdminHomeDashboardComponent
} from "./feats/admin-portal/components/admin-home-dashboard/admin-home-dashboard.component";
import {
  AdminArchiveDashboardComponent
} from "./feats/admin-portal/components/admin-archive-dashboard/admin-archive-dashboard.component";
import {
  RepairJobContainerComponent
} from "./feats/admin-portal/components/repair-job-container/repair-job-container.component";
import {
  RepairJobDetailsFormComponent
} from "./feats/admin-portal/components/repair-job-details-form/repair-job-details-form.component";
import {AdminProfileComponent} from "./feats/admin-portal/components/admin-profile/admin-profile.component";
import {AuthCallbackComponent} from "./feats/authentication/components/auth-callback/auth-callback.component";
import {AuthGuard} from "@auth0/auth0-angular";
import {
  NewRepairJobContainerComponent
} from "./feats/admin-portal/components/new-repair-job-container/new-repair-job-container.component";

const routes: Routes = [
  {
    path: '',
    component: CustomerPortalContainerComponent
  },
  {
    path: 'track',
    component: JobTrackerContainerComponent
  },
  {
    path: 'request',
    component: RequestFormContainerComponent
  },
  {
    path: 'admin',
    component: AdminPortalContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'jobs',
        pathMatch: 'full'
      },
      {
        path: 'jobs',
        component: AdminHomeDashboardComponent
      },
      {
        path: 'jobs/:jobNumber',
        component: RepairJobContainerComponent
      },
      {
        path: 'jobs/:jobNumber/edit-details',
        component: RepairJobDetailsFormComponent
      },
      {
        path: 'archive',
        component: AdminArchiveDashboardComponent
      },
      {
        path: 'profile',
        component: AdminProfileComponent
      },
      {
        path: 'new-job',
        component: NewRepairJobContainerComponent
      }
    ]
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
