import { Component } from '@angular/core';
import {RepairJob} from "../../../../domain/repair-job.dto";
import {SecureResourceService} from "../../../../services/secure-resource.service";


@Component({
  selector: 'app-admin-home-dashboard',
  templateUrl: './admin-home-dashboard.component.html',
  styleUrls: ['./admin-home-dashboard.component.scss']
})
export class AdminHomeDashboardComponent {
  repairJobs: RepairJob[] = []
  constructor(
    resourceService: SecureResourceService
  ) {
    resourceService.getRepairJobs().subscribe(data => {
      this.repairJobs = data;
    });
  }

}
