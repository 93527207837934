import { Injectable } from '@angular/core';
import { resourceServer } from '../config/resource-server.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const resourcePath = `${resourceServer}/open/api`;

@Injectable({
  providedIn: 'root'
})
export class OpenResourceService {

  constructor(
    private http: HttpClient
  ) { }

  getRepairStatus(jobNumber: String): Observable<any> {
    return this.http.get(`${resourcePath}/repair_job/${jobNumber}`);
  }

  addRepairRequest(repairRequest: any): Observable<any> {
    return this.http.post(`${resourcePath}/repair_job`, repairRequest);
  }
}
