import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appDashboardContainer]'
})
export class DashboardContainerDirective {

  constructor(
    public viewContainerRef: ViewContainerRef
  ) { }

}
