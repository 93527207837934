import {DatePipe} from "@angular/common";

const datePipe = new DatePipe('en-AU');

export function formatDateForHtmlInput(date: Date) {
  return datePipe.transform(date, 'YYYY-MM-dd');
}

export function formatHtmlDateInputToDateObj(date: String | null) {
  if (!date) {
    return null;
  }

  return new Date(`${date}`);
}

export function formatHtmlDateInputToIsoDate(date: String | null): string | null {
  const dateObj = formatHtmlDateInputToDateObj(date);

  if (!dateObj) {
    return null;
  }

  return dateObj.toISOString();
}
