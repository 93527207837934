import {Component, inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {getDownloadURL, listAll, ref, Storage, uploadBytes, deleteObject } from '@angular/fire/storage';
import {ActivatedRoute} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {Image} from "primeng/image";
import {authState, getAuth, signInWithCustomToken} from "@angular/fire/auth";
import {AuthService} from "@auth0/auth0-angular";

const STATE_VIEW: string = '[images] view';
const STATE_UPLOAD: string = '[images] upload';
const STATE_DELETE: string = '[image] delete';

interface ImageMeta {
  url: string,
  reference: string,
  name: string
}

@Component({
  selector: 'app-repair-job-images',
  templateUrl: './repair-job-images.component.html',
  styleUrls: ['./repair-job-images.component.scss']
})
export class RepairJobImagesComponent implements OnInit {
  @ViewChildren(Image) imageComponents: QueryList<Image> | undefined;
  jobNumber: string | null = null;
  imageMetaList: ImageMeta[] = [];
  mode: string = STATE_VIEW;
  selectedFiles?: FileList;

  constructor(
    private route: ActivatedRoute,
    private storage: Storage = inject(Storage),
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) {
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');
  }

  ngOnInit() {
    this.fetchImages();
    // const auth = getAuth();
    // this.authService.getAccessTokenSilently().subscribe((token) => signInWithCustomToken(auth, token));
  }


  onAdd() {
    this.mode = STATE_UPLOAD;
  }

  onCancel() {
    this.mode = STATE_VIEW;
  }

  onUpload() {
    if (!this.selectedFiles) {
      console.error("no selected files");
      return
    }

    const file = this.selectedFiles.item(0)

    if (!file) {
      console.error("Could not retrieve file");
      return
    }

    const imageRef = ref(this.storage, `jobs/${this.jobNumber}/images/${file.name}`);

    uploadBytes(imageRef, file).then(() => {
      this.selectedFiles = undefined;
      this.fetchImages();
      this.mode = STATE_VIEW;
    });
  }

  protected readonly STATE_VIEW = STATE_VIEW;
  protected readonly STATE_UPLOAD = STATE_UPLOAD;

  onChooseFile($event: any) {
    this.selectedFiles = $event.target.files;
  }

  onDelete(reference: string) {
    const imageRef = ref(this.storage, reference);

    deleteObject(imageRef)
      .then(() => this.fetchImages())
      .catch((error) => console.error(error));
  }

  confirmDelete(imageMeta: ImageMeta) {
    this.mode = STATE_DELETE;

    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this image?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(imageMeta.reference);
        this.mode = STATE_VIEW;
      },
      reject: () => {
        this.mode = STATE_VIEW;
      },
    });

  }

  onShow(imageMeta: ImageMeta) {
    if (this.mode != STATE_DELETE) return;

    if (this.imageComponents) this.imageComponents.forEach((item) => {
      if (item.src == imageMeta.url) {
        item.closePreview();
      }
    }); // close any previews
  }

  private fetchImages() {
    if (!this.jobNumber) {
      console.error("no job number provided");
      return
    }

    this.imageMetaList = [];

    const imagesRef = ref(this.storage, `jobs/${this.jobNumber}/images`);

    listAll(imagesRef).then((res) => {
      res.items.forEach((imageRef) => {
        getDownloadURL(imageRef).then((url) => {
          this.imageMetaList.push({
            url: url,
            reference: imageRef.fullPath,
            name: imageRef.name
          });
        })
      })
    })
  }
}
