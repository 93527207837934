import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OpenResourceService } from 'src/app/services/open-resource.service';

interface RepairJobStatus {
  job_number: string;
  status: string;
  posted_date: string;
  expected_completion_date: string;
}

@Component({
  selector: 'app-job-tracker-container',
  templateUrl: './job-tracker-container.component.html',
  styleUrls: ['./job-tracker-container.component.scss']
})
export class JobTrackerContainerComponent implements OnInit {
  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl('')
  });

  formSubmitted = false;
  repairJobStatus: RepairJobStatus = {
    job_number: "",
    status: "",
    posted_date: "",
    expected_completion_date: ""
  };

  constructor(
    private openRes: OpenResourceService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const jobNumber = this.searchForm.value.search;
    this.openRes.getRepairStatus(jobNumber).subscribe( data => {
      this.repairJobStatus = { ...data };
      this.formSubmitted = true;
    });

  }

}
