import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-archive-dashboard',
  templateUrl: './admin-archive-dashboard.component.html',
  styleUrls: ['./admin-archive-dashboard.component.scss']
})
export class AdminArchiveDashboardComponent {

}
