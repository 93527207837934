<div class="rounded-lg grid grid-cols-1 justify-items-end mb-4 bg-gray-100 p-2">
  <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" (click)="onSubmit()">Save</button>
</div>
<form [formGroup]="form" class="grid grid-cols-1 gap-2">
  <p-panel header="Customer Details" formGroupName="customer">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">First name</div>
      <div class="col-span-4">
        <input type="text" formControlName="firstName" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Last name</div>
      <div class="col-span-4">
        <input type="text" formControlName="lastName" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Email address</div>
      <div class="col-span-4">
        <input type="text" formControlName="email" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Phone number</div>
      <div class="col-span-4">
        <input type="text" formControlName="phoneNumber" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Postal address</div>
      <div class="col-span-4">
        <input type="text" formControlName="postalAddress" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>
    </div>
  </p-panel>
  <p-panel header="Customer Request" formGroupName="customerRequest">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Problem description</div>
      <div class="col-span-4">
        <input type="text" formControlName="problemDescription" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Repair requests</div>
      <div class="col-span-4">
        <input type="text" formControlName="repairRequests" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Spending limit</div>
      <div class="col-span-4">
        <input type="number" formControlName="spendingLimit" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Requested date</div>
      <div class="col-span-4">
        <input type="date" formControlName="requestedDate" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>
    </div>
  </p-panel>
  <p-panel header="Job Details">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Generate Job Number</div>
      <div class="col-span-4">
        <p-inputSwitch (click)="onGenerateJobNumberToggle()"></p-inputSwitch>
      </div>

      <div *ngIf="!generateJobNumber" class="justify-self-end font-bold col-span-2">Job Number</div>
      <div *ngIf="!generateJobNumber" class="col-span-4">
        <input type="text" formControlName="jobNumber" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Status</div>
      <div class="col-span-4">
        <input type="text" formControlName="status" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Arrival date</div>
      <div class="col-span-4">
        <input type="date" formControlName="arrivalDate" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Posted date</div>
      <div class="col-span-4">
        <input type="date" formControlName="postedDate" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Expected completion date</div>
      <div class="col-span-4">
        <input type="date" formControlName="expectedCompletionDate" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Estimated cost</div>
      <div class="col-span-4">
        <input type="number" formControlName="estimatedCost" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Final cost</div>
      <div class="col-span-4">
        <input type="number" formControlName="finalCost" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Postage tracking number</div>
      <div class="col-span-4">
        <input type="text" formControlName="postageTrackingNumber" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>
    </div>
  </p-panel>
  <p-panel header="Timepiece Details" formGroupName="timepiece">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Brand</div>
      <div class="col-span-4">
        <input type="text" formControlName="brand" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Case material</div>
      <div class="col-span-4">
        <input type="text" formControlName="caseMaterial" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Case condition</div>
      <div class="col-span-4">
        <textarea formControlName="caseCondition" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2">Band/Bracelet material</div>
      <div class="col-span-4">
        <input type="text" formControlName="bandMaterial" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Band/Bracelet condition</div>
      <div class="col-span-4">
        <textarea formControlName="bandCondition" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2">Face colour</div>
      <div class="col-span-4">
        <input type="text" formControlName="faceColour" class="rounded bg-gray-100 border-gray-200 w-full" />
      </div>

      <div class="justify-self-end font-bold col-span-2">Face condition</div>
      <div class="col-span-4">
        <textarea formControlName="faceCondition" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2" >Serial number(s)</div>
      <div class="col-span-4 gap-x-2" formArrayName="serialNumbers">
        <div class="" *ngFor="let serialNumber of serialNumbers.controls; let i = index">
          <input id="serial-{{ i }}" type="text" [formControlName]="i" class="rounded bg-gray-100 border-gray-200 w-3/4" />
          <button *ngIf="i !== 0" class="rounded bg-gray-800 p-2 text-white" (click)="onRemoveSerial(i)">-</button>
        </div>
      </div>
      <div class="col-start-3 col-span-4"><button class="rounded bg-gray-800 p-2 text-white" (click)="onAddSerial()">Add another serial</button></div>
    </div>
  </p-panel>
  <p-panel header="Problem Details" formGroupName="problemDetail">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Description</div>
      <div class="col-span-4">
        <textarea formControlName="description" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2">Todo</div>
      <div class="col-span-4">
        <textarea formControlName="todo" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2">Movement</div>
      <div class="col-span-4">
        <textarea formControlName="movement" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>

      <div class="justify-self-end font-bold col-span-2">Required Parts</div>
      <div class="col-span-4">
        <textarea formControlName="requiredParts" class="rounded bg-gray-100 border-gray-200 w-full"></textarea>
      </div>
    </div>
  </p-panel>
</form>
