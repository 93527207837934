<p-tabView [style]="{'padding': 0}">
  <p-tabPanel header="Details" [style]="{'padding': 0, 'margin': 0}">
<!--    <div class="rounded-lg grid grid-cols-1 justify-items-end mb-4 bg-gray-100 p-2">-->
    <div class="rounded-lg grid grid-cols-1 justify-items-end mb-4 bg-gray-100 p-2">
      <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" *ngIf="mode === 'view'" (click)="onEdit()">Edit</button>
      <button class="rounded-lg bg-warmgray-700 text-white p-3 font-bold border-2" *ngIf="mode === 'edit'" (click)="onSave()">Save</button>
    </div>
    <app-repair-job-details *ngIf="mode === 'view'" [job]="job$ | async"></app-repair-job-details>
    <app-repair-job-details-form *ngIf="mode === 'edit'" [formData]="job$ | async" [toSubmit]="toSubmit" (formEvent)="toggleMode($event)"></app-repair-job-details-form>
  </p-tabPanel>
  <p-tabPanel header="Notes" [disabled]="mode === 'edit'">
    <app-repair-job-notes [jobNumber]="(job$ | async)?.jobNumber"></app-repair-job-notes>
  </p-tabPanel>
  <p-tabPanel header="Images" [disabled]="mode === 'edit'">
    <app-repair-job-images></app-repair-job-images>
  </p-tabPanel>
</p-tabView>
