import {Component} from '@angular/core';
import {SecureResourceService} from "../../../../services/secure-resource.service";
import {Observable} from "rxjs";
import {RepairJob} from "../../../../domain/repair-job.dto";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-repair-job-container',
  templateUrl: './repair-job-container.component.html',
  styleUrls: ['./repair-job-container.component.scss']
})
export class RepairJobContainerComponent {
  job$: Observable<RepairJob> = new Observable<RepairJob>();
  mode: String = 'view';
  toSubmit: boolean = false;

  constructor(
    private resourceService: SecureResourceService,
    private route: ActivatedRoute
  ) {
    this.fetchRepairJob();
  }

  toggleMode(formEvent: string) {
    if (formEvent === 'submitted') {
      this.mode = 'view';
      this.toSubmit = false;
      this.fetchRepairJob();
    }
  }

  onEdit() {
    this.mode = 'edit';
  }

  onSave() {
    this.toSubmit = true;
  }

  private fetchRepairJob() {
    const jobNumber: string | null = this.route.snapshot.paramMap.get('jobNumber');
    if (jobNumber) {
      this.job$ = this.resourceService.getRepairJob(jobNumber);
    }
  }


}
