import {Component, Input} from '@angular/core';
import {NewRepairJobState as State} from "../../states/new-repair-job-state";

@Component({
  selector: 'app-new-repair-job-receipt',
  templateUrl: './new-repair-job-receipt.component.html',
  styleUrls: ['./new-repair-job-receipt.component.scss']
})
export class NewRepairJobReceiptComponent {
  @Input() jobNumber: string | null = null;
  @Input() state: string | null = null;

  get message() {
    if (this.state === State.STATE_SUCCESS) {
      return this.successMessage
    }

    if (this.state === State.STATE_FAIL) {
      return "The job did not get created. Something went wrong."
    }

    return "Ooops. Something went wrong"
  }

  get successMessage() {
    if (!this.jobNumber) {
      return "There was an issue retrieving the job number. Go home to check if the job has appeared in the list.";
    }

    return `The new job has been created with job number [${this.jobNumber}]`;
  }

  get isViewable(): boolean {
    return this.jobNumber !== null && this.state === State.STATE_SUCCESS;
  }
}
