export function snakeToCamel(obj: Record<string, any>): Record<string, any> {
  const newObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
    newObj[newKey] = typeof value === "object" && value !== null ? snakeToCamel(value) : value;
  }

  return newObj;
}

export function camelToSnake(obj: Record<string, any>): Record<string, any> {
  const newObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = key.replace(/[A-Z]/g, (m) => "_" + m.toLowerCase());

    if (Array.isArray(value)) {
      newObj[newKey] = value.map((item: any) =>
        typeof item === "object" && item !== null ? camelToSnake(item) : item
      );
    } else {
      newObj[newKey] =
        typeof value === "object" && value !== null ? camelToSnake(value) : value;
    }
  }

  return newObj;
}

export function camelToSnakeStr(str: String): String {
  return str.replace(/[A-Z]/g, (m) => "_" + m.toLowerCase());
}
