<div class="absolute inset-0 shadow-lg bg-warmgray-600 h-64"></div>
<div class="relative p-4">
    <button class="text-white underline p-4 rounded-md hover:bg-gray-800 hover:no-underline float-right">Login</button>
</div>
<div class="relative max-w-3xl mx-auto">
    <div class="relative py-8">
        <button class="text-3xl text-white" routerLink="/">&lt; Back</button>
    </div>
    <div class="relative bg-white shadow-lg rounded-lg p-20">
        <div class="mx-w-md mx-auto">
            <div *ngIf="!formSubmitted">
                <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                    <div class="py-4">
                        <input type="text" class="w-full" placeholder="Job Number" formControlName="search">
                    </div>
                    <div class="py-4">
                        <button type="submit" class="p-4 bg-blue-400 text-white">Track</button>
                    </div>
                </form>
            </div>
            <div *ngIf="formSubmitted">
                <div class="py-4">
                    <!-- <div class="font-bold">
                        Job Number
                    </div> -->
                    <div class="text-5xl text-center">
                        {{ repairJobStatus.job_number }}
                    </div>
                </div>
                <div class="py-4">
                    <div class="font-bold">
                        Status
                    </div>
                    <div>
                        {{ repairJobStatus.status }}
                    </div>
                </div>
                <div class="py-4">
                    <div class="font-bold">
                        Posted date
                    </div>
                    <div>
                        {{ repairJobStatus.posted_date ? repairJobStatus.posted_date : "TBA" }}
                    </div>
                </div>
                <div class="py-4">
                    <div class="font-bold">
                        Expected completion date
                    </div>
                    <div>
                        {{ repairJobStatus.expected_completion_date ? repairJobStatus.expected_completion_date : "TBA" }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>