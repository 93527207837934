import {Component, Inject, ViewChild} from '@angular/core';
import {DashboardContainerDirective} from "../../directives/dashboard-container.directive";
import {AdminHomeDashboardComponent} from "../admin-home-dashboard/admin-home-dashboard.component";
import {AdminArchiveDashboardComponent} from "../admin-archive-dashboard/admin-archive-dashboard.component";
import {AuthService} from "@auth0/auth0-angular";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-admin-portal-container',
  templateUrl: './admin-portal-container.component.html',
  styleUrls: ['./admin-portal-container.component.scss']
})
export class AdminPortalContainerComponent {
  @ViewChild(DashboardContainerDirective, {static: true}) dashboardContainer!: DashboardContainerDirective;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService
  ) {}

  loadDash(dashboard: string) {
    const viewContainer = this.dashboardContainer.viewContainerRef;

    viewContainer.clear();

    switch (dashboard) {
      case 'home':
        viewContainer.createComponent(AdminHomeDashboardComponent);
        return;

      case 'archive':
        viewContainer.createComponent(AdminArchiveDashboardComponent);
        return;

    }

  }
}
