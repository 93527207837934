import {Component, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {NewRepairJobState as State} from "../../states/new-repair-job-state";
import {formatHtmlDateInputToIsoDate} from "../../../../utils/date.util";

@Component({
  selector: 'app-new-repair-job-form',
  templateUrl: './new-repair-job-form.component.html',
  styleUrls: ['./new-repair-job-form.component.scss']
})
export class NewRepairJobFormComponent {
  @Output() state: EventEmitter<string> = new EventEmitter<string>()
  @Output() data: EventEmitter<any> = new EventEmitter<any>()

  generateJobNumber: Boolean = false;

  form = this.formBuilder.group({
    jobNumber: [''],
    status: [''],
    postedDate: [''],
    arrivalDate: [''],
    expectedCompletionDate: [''],
    estimatedCost: [0],
    finalCost: [0],
    postageTrackingNumber: [''],
    createdDate: [new Date()],
    customer: this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      postalAddress: [''],
      phoneNumber: ['']
    }),
    customerRequest: this.formBuilder.group({
      problemDescription: [''],
      repairRequests: [''],
      spendingLimit: [''],
      requestedDate: ['']
    }),
    timepiece: this.formBuilder.group({
      brand: [''],
      caseMaterial: [''],
      caseCondition: [''],
      bandMaterial: [''],
      bandCondition: [''],
      faceColour: [''],
      faceCondition: [''],
      serialNumbers: this.formBuilder.array([''])
    }),
    problemDetail: this.formBuilder.group({
      movement: [''],
      description: [''],
      todo: [''],
      requiredParts: ['']
    })
  });

  get timepiece() {
    return this.form.get('timepiece') as FormGroup;
  }

  get serialNumbers() {
    return this.timepiece.get('serialNumbers') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  onAddSerial() {
    this.serialNumbers.push(this.formBuilder.control(''));
  }

  onRemoveSerial(position: number) {
    this.serialNumbers.removeAt(position);
  }

  onGenerateJobNumberToggle() {
    this.generateJobNumber = !this.generateJobNumber
  }

  onSubmit() {
    this.state.emit(State.STATE_SUBMITTING);

    const submittedData = this.form.getRawValue();
    const postedDate = formatHtmlDateInputToIsoDate(submittedData.postedDate);
    const arrivalDate = formatHtmlDateInputToIsoDate(submittedData.arrivalDate);
    const expectedCompletionDate = formatHtmlDateInputToIsoDate(submittedData.expectedCompletionDate);
    const jobNumber = !this.generateJobNumber ? submittedData.jobNumber : null;
    const createdDate = submittedData.createdDate?.toISOString();
    const requestedDate = formatHtmlDateInputToIsoDate(submittedData.customerRequest.requestedDate);
    const customerRequest = {
      ...submittedData.customerRequest,
      requestedDate
    }
    const serialNumbers = Object.values(submittedData.timepiece.serialNumbers);

    const newRepairJob= {
      ...submittedData,
      jobNumber,
      postedDate,
      arrivalDate,
      createdDate,
      expectedCompletionDate,
      customerRequest,
      timepiece: {
        ...submittedData.timepiece,
        serialNumbers
      }
    }

    this.data.emit(newRepairJob);
  }

}
