import { Injectable } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {Auth, signInWithCustomToken} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SecureResourceService} from "./secure-resource.service";

@Injectable({
  providedIn: 'root'
})
export class WacrAuthService {

  constructor(
    private auth0: AuthService,
    private resource: SecureResourceService,
    private firebaseAuth: Auth
  ) { }

  login() {
    this.auth0.loginWithRedirect({ appState: {target: '/admin'}}).subscribe(() => {
      this.auth0.user$.subscribe((user) => {
        if (!user) return;
        const uid = user.email ? user.email : 'admin-user';
        this.resource.getFirebaseCustomToken(uid).subscribe((data) => {
          signInWithCustomToken(this.firebaseAuth, data.token).then((r) => {});
        });
      });
    });
  }

}
