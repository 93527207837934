<div class="grid grid-cols-1 gap-3">
  <p-panel header="Customer Details" [toggleable]="true">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Customer name</div>
      <div class="col-span-4">{{ job?.customer?.firstName }} {{ job?.customer?.lastName }}</div>

      <div class="justify-self-end font-bold col-span-2">Email address</div>
      <div class="col-span-4">{{ job?.customer?.email }}</div>

      <div class="justify-self-end font-bold col-span-2">Phone number</div>
      <div class="col-span-4">{{ job?.customer?.phoneNumber }}</div>

      <div class="justify-self-end font-bold col-span-2">Postal address</div>
      <div class="col-span-4">{{ job?.customer?.postalAddress }}</div>
    </div>
  </p-panel>
  <p-panel header="Job Details" [toggleable]="true">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Job Number</div>
      <div class="col-span-4">{{ job?.jobNumber }}</div>

      <div class="justify-self-end font-bold col-span-2">Status</div>
      <div class="col-span-4">{{ job?.status }}</div>

      <div class="justify-self-end font-bold col-span-2">Arrival date</div>
      <div class="col-span-4">{{ job?.arrivalDate | date: 'dd/MM/yyyy' }}</div>

      <div class="justify-self-end font-bold col-span-2">Posted date</div>
      <div class="col-span-4">{{ job?.postedDate | date: 'dd/MM/yyyy' }}</div>

      <div class="justify-self-end font-bold col-span-2">Expected completion date</div>
      <div class="col-span-4">{{ job?.expectedCompletionDate | date: 'dd/MM/yyyy' }}</div>

      <div class="justify-self-end font-bold col-span-2">Estimated cost</div>
      <div class="col-span-4">{{ job?.estimatedCost }}</div>

      <div class="justify-self-end font-bold col-span-2">Final cost</div>
      <div class="col-span-4">{{ job?.finalCost }}</div>

      <div class="justify-self-end font-bold col-span-2">Postage tracking number</div>
      <div class="col-span-4">{{ job?.postageTrackingNumber }}</div>
    </div>
  </p-panel>
  <p-panel header="Timepiece Details" [toggleable]="true">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Brand</div>
      <div class="col-span-4">{{ job?.timepiece?.brand }}</div>

      <div class="justify-self-end font-bold col-span-2">Case material</div>
      <div class="col-span-4">{{ job?.timepiece?.caseMaterial }}</div>

      <div class="justify-self-end font-bold col-span-2">Case condition</div>
      <div class="col-span-4">{{ job?.timepiece?.caseCondition }}</div>

      <div class="justify-self-end font-bold col-span-2">Band/Bracelet material</div>
      <div class="col-span-4">{{ job?.timepiece?.bandMaterial }}</div>

      <div class="justify-self-end font-bold col-span-2">Band/Bracelet condition</div>
      <div class="col-span-4">{{ job?.timepiece?.bandCondition }}</div>

      <div class="justify-self-end font-bold col-span-2">Face colour</div>
      <div class="col-span-4">{{ job?.timepiece?.faceColour }}</div>

      <div class="justify-self-end font-bold col-span-2">Face condition</div>
      <div class="col-span-4">{{ job?.timepiece?.faceCondition }}</div>

      <div class="justify-self-end font-bold col-span-2">Serial number(s)</div>
      <div class="col-span-4">
        <!-- TODO: iterate over serial numbers -->
                  <div *ngFor="let serialNumber of serialNumbers">{{ serialNumber }}</div>
      </div>
    </div>
  </p-panel>
  <p-panel header="Problem Details" [toggleable]="true">
    <div class="grid grid-cols-6 gap-6">
      <div class="justify-self-end font-bold col-span-2">Description</div>
      <div class="col-span-4">{{ job?.problemDetail?.description }}</div>

      <div class="justify-self-end font-bold col-span-2">Todo</div>
      <div class="col-span-4">{{ job?.problemDetail?.todo }}</div>

      <div class="justify-self-end font-bold col-span-2">Movement</div>
      <div class="col-span-4">{{ job?.problemDetail?.movement }}</div>

      <div class="justify-self-end font-bold col-span-2">Required Parts</div>
      <div class="col-span-4">{{ job?.problemDetail?.requiredParts }}</div>
    </div>
  </p-panel>
</div>
